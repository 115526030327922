import Swal from "sweetalert2";
export const isStoreOpen = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });
  
    // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
    const currentDayOfWeek = new Date(currentDate).getDay();
  
    // Check if it's Thursday (Thursday is 4)
    if (currentDayOfWeek === 4) {
      return false; // Store is closed on Thursdays
    }
  
    // Define the store's opening hours
    const openingHours = [
      { day: 0, start: "00:00", end: "23:59" }, // Sunday
      { day: 1, start: "00:00", end: "23:59" }, // Monday
      { day: 2, start: "00:00", end: "23:59" }, // Tuesday
      { day: 3, start: "00:00", end: "23:59" }, // Wednesday
        { day: 4, start: "00:00", end: "23:59" }, // Thursday
      { day: 5, start: "00:00", end: "23:59" }, // Friday
      { day: 6, start: "00:00", end: "23:59" }, // Saturday
    ];

    // Check if the current time is within any of the store's opening hours
    const isOpen = openingHours.some((hour) => hour.day === currentDayOfWeek);
  
    return isOpen;
};

  

export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours: 10:50 AM - 3:00 PM / 5:00PM-10:30PM Eastern Time. Closed on Thursdays.',

    })
  }
