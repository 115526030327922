import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css";
import "react-modal-video/css/modal-video.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "./assets/css/common.css";
import "rsuite/dist/rsuite.min.css";
import "./assets/other/switcher/switcher.css";
import "./assets/css/style.css";

import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ScrollTop from "./constent/ScrollTop";
import AboutUs from "./pages/AboutUs";
import Faq from "./pages/Faq";

import ComingSoon from "./pages/ComingSoon";

import Account from "./pages/Account";
import MenuStyle4 from "./pages/MenuStyle4";

import ShopStyle2 from "./pages/ShopStyle2";
import ShopCheckout from "./pages/ShopCheckout";
import ContactUs from "./pages/ContactUs";
import SignIn from "./elements/SignIn";
import OnlineOrder from "./pages/OnlineOrder";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantThunk } from "./store/restaurants";
import Home2 from "./pages/Home2";
import Order from "./pages/Order";
import MenuStyle3 from "./pages/MenuStyle3";
import Lottie from "react-lottie-player";
import loadingLottie from './assets/json/loading.json'


function App() {
  const dispatch = useDispatch()
  const restaurant = useSelector((state) => state.restaurant.restaurant);
  const [loading, setLoading] = useState(false);



  
  useEffect(() => { 
    dispatch(getRestaurantThunk(6)).then(() => {
      setLoading(false);
    })
  },[dispatch])
  return (
    <>
      <div className="page-wraper">
          {loading ? (

<div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
<Lottie
  loop
  animationData={loadingLottie}
  play
  style={{ width: 200, height: 200, margin: 'auto', display: 'block' }}
/>
<h4>Loading Menu...</h4>
</div>
          ) : (
        <Router>
          {/* <SignIn /> */}
          <ScrollTop />
          <Routes>
              {/* <Route exact path="/" element={<Home2 restaurant={restaurant} />} /> */}
              <Route path="/online-order" element={<OnlineOrder restaurant={restaurant}/>} />
              <Route default path="/" element={<MenuStyle3 restaurant={restaurant}/>} />

              <Route path="/about" element={<AboutUs />} />
              <Route path="/faq" element={<Faq />} />

              <Route path="/checkout" element={<ShopCheckout />} />

              <Route path="/contact" element={<ContactUs />} />
            <Route path="/order-confirmed/:tip/:name/:phone/:address" element={<ComingSoon />} />
            <Route path="/account" element={<Account />} />
            <Route path='/order/:id/:restaurant_id' element={<Order />} />
            </Routes>
            
          {/* <Switcher />// */}
        </Router>
          )}
      </div>
    </>
  );
}

export default App;
