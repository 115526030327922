import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import Menu from "./Menu";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context/AppContext";

const Header = () => {
  const {
    headerClass,
    headerSidebar,
    setHeaderSidebar,
    language,
    setLanguage,
    changedCart
  } = useContext(Context);
  const [cart, setCart] = useState(false);
  const cartRef = useRef([]);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [scroll, setScroll] = useState(false);
  const [currentCart, setCurrentCart] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart"));

    if(items){ 
      setCurrentCart(items);
    }
  }, [changedCart]);

  const deletItems = (ind) => {
    cartRef.current[ind].classList.add("cartListItems");
    setTimeout(() => {
      cartRef.current[ind].remove();
    }, 500);
  };

  const scrollHandler = () => {
    if (window.scrollY > 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const handleLanguage = () => {
    setLanguage(language === "en" ? "cn" : "en");
  };


  return (
    <header className={`site-header mo-left header header-transparent transparent-white style-1 ${scroll ? "is-fixed" : ""}`}>
      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar clearfix">
          <div className="container clearfix">
            {/* <div className="logo-header mostion">
              {headerClass ? (
                <div className={`anim-logo ${scroll ? "" : "anim-logo-white"}`} onClick={() => navigate("/")}>
                  <img src={IMAGES.logo} alt="/"  style={{height:'60px'}}/>
                  
                </div>
                
              ) : (
                <div className="anim-logo" onClick={() => navigate("/")} >
                  <img src={IMAGES.logo} alt="/" style={{height:'60px'}} />
                  
                </div>
              )}
            </div> */}
            <div className="d-lg-none">
              <div className="d-flex align-items-center justify-content-end">
                {/*language change */}
                <button
                  type="button"
                  className="btn btn-white btn-square bg-light btn-shadow mt-2"
                  style={{ marginRight: '12px' }}
                  onClick={handleLanguage}
                >
                  <i className="fas fa-language"></i>
                </button>
                <button
                  type="button"
                  className={`btn btn-white btn-square bg-light btn-shadow mt-2`}
                  onClick={() => navigate("/checkout")}
                >
                  <i className="flaticon-shopping-bag-1"></i>
                  <span className="badge">{currentCart?.length}</span>
                </button>
                <button
                  className={`navbar-toggler collapsed navicon btn-shadow  ${headerSidebar ? "open" : ""}`}
                  type="button"
                  onClick={() => {
                    setHeaderSidebar(!headerSidebar);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
            <div className="extra-nav">
              <div className="extra-cell">
                <ul>
                  {/**language change */}
                  <li>
                    <button
                      type="button"
                      className="btn btn-white btn-square btn-shadow"
                      onClick={handleLanguage}
                    >
                      <i className="fas fa-language"></i>
                    </button>
                  </li>
                  {/* <li>
                    <button
                      className="btn btn-white btn-square btn-shadow"
                      onClick={handleNavigateToAccount}
                    >
                      <i className="flaticon-user"></i>
                    </button>
                  </li> */}
                  <li>
                    <button
                      type="button"
                      className="btn btn-white btn-square btn-shadow cart-btn"
                      onClick={() => navigate("/checkout")}
                    >
                      <i className="flaticon-shopping-bag-1"></i>
                      <span className="badge">
                        {currentCart?.length}
                      </span>
                    </button>
                    <div
                      style={{
                        transition: "all 0.5s",
                        opacity: cart ? "1" : "0",
                      }}
                    >
                      <ul
                        className="dropdown-menu cart-list"
                        style={{
                          display: cart ? "block" : "",
                          transitionDuration: "0.5s",
                          opacity: cart ? "1" : "0",
                          overflow: "hidden",
                        }}
                      >
                        {currentCart?.map((cartItem, index) => (
                          <li
                            className="cart-item"
                            key={index}
                            ref={(node) => cartRef.current.push(node)}
                          >
                            <div className="media">
                              <div className="media-left">
                                <div onClick={() => navigate("/online-order")}>
                                  {cartItem?.Item?.image_url && (
                                    <img
                                      alt="/"
                                      className="media-object"
                                      src={cartItem?.Item?.image_url}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="media-body">
                                <h6 className="dz-title">
                                  <div onClick={() => navigate("/product-detail")}>
                                    {cartItem?.Item?.name}
                                  </div>
                                </h6>
                                <span className="dz-price">
                                  ${cartItem?.item_price}
                                </span>
                                <span
                                  className="item-close"
                                  onClick={() => deletItems(index)}
                                >
                                  &times;
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                        {currentCart?.length > 0 && (
                          <li className="cart-item text-center d-flex justify-content-between">
                            <h6 className="text-primary mb-0">Total:</h6>
                            <h6 className="text-primary mb-0">${calculateTotal(currentCart) || 0}</h6>
                          </li>
                        )}
                        {currentCart?.length > 0 && (
                          <li className="text-center d-flex">
                            <button
                              className="btn btn-primary me-2 w-100 d-block btn-hover-1"
                              onClick={() => {
                                setCart(false);
                                navigate("/checkout");
                              }}
                            >
                              <span>View Cart</span>
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`header-nav navbar-collapse justify-content-end ${headerSidebar ? "show" : ""}`}
              id="navbarNavDropdown"
            >
              <Menu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const calculateTotal = (cart) => {
  let total = 0;
  cart.forEach((item) => {
    total += item.item_price;
  });
  return total;
};

export default Header;
