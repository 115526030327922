import React, { useState } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Modal } from "react-bootstrap";
import { logout } from "../store/session";
import { Context } from "../context/AppContext";
import { useContext, useEffect } from "react";
import { getHistoryThunk } from "../store/order";

const Account = () => {
  const { guestCheckOut, setGuestCheckOut, setShowSignInForm } = useContext(Context);
  const currentUser = useSelector((state) => state.session.user);
  const orders = useSelector((state) => state.order.history);
  const dispatch = useDispatch();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);



  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  useEffect(() => {
    if (currentUser?.phone) {
      dispatch(getHistoryThunk(currentUser?.phone))
    }
  }, [dispatch, currentUser?.phone])

  const sessionUser = currentUser?.role === 'Session';

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  }

  return (
    <div className="page-content bg-white">
      <Header />

      {!currentUser ? (
        <div className="content-inner mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p>
                  <strong>Hi Customer:</strong> Please log in to view your order history.
                </p>
                <button className="btn btn-primary" onClick={() => setShowSignInForm(true)}>Log In</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div className="content-inner mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Hi Customer:</strong>{sessionUser ? 'GUEST' : currentUser?.name}</p>
                  <p><strong>Phone:</strong>{sessionUser ? null : currentUser?.phone}</p>
                </div>
              </div>
              <div className="row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>Order Total</th>
                      <th>Payment Method</th>
                      <th>Order Status</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => (
                      <tr key={order.id} onClick={() => handleOrderClick(order)}>
                        <td>{order.order_id}</td>
                        <td>${order.order_total}</td>
                        <td>{order.payment_method}</td>
                        <td>{order.order_status}</td>
                        <td>{formatDate(order.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Button className="mt-3" onClick={handleLogout} variant="danger">Sign Out</Button>
            </div>
          </div>
        )}
 <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Order Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedOrder && (
      <div>
        <p><strong>Order ID:</strong> {selectedOrder.order_id}</p>
        <p><strong>Payment Method:</strong> {selectedOrder.payment_method}</p>
        <p><strong>Order Status:</strong> {selectedOrder.order_status}</p>
        <p><strong>Created At:</strong> {formatDate(selectedOrder.createdAt)}</p>
        <p><strong>Tip:</strong> ${selectedOrder.tip}</p>
        <p><strong>Subtotal:</strong> ${selectedOrder.subtotal}</p>
        <p><strong>Order Total:</strong> ${selectedOrder.order_total}</p>
        <hr />
        <p><strong>Items:</strong></p>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {selectedOrder.OrderItems.map((item, index) => (
            <li key={index} style={{ marginBottom: '5px' }}>
              <span style={{ marginRight: '10px' }}>{item.Item.name}</span>
              <span style={{ fontWeight: 'bold' }}>${item.item_price}</span>
            </li>
          ))}
        </ul>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default Account;
