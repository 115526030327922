import { Link } from "react-router-dom"

const CommonBanner = ({ img, title, subtitle }) => {
  return (
    <div
      className="dz-bnr-inr style-1 text-center bg-parallax bg-parallax"
      style={{
        backgroundImage: `url(https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/864fb917-f3c0-4495-bd56-b92230cd4b75/derdl4m-eb9386ca-b7d7-4182-b6b5-27c5498ea2a7.jpg/v1/fill/w_900,h_467,q_75,strp/japanese_restaurant_by_chiakihayano_derdl4m-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzg2NGZiOTE3LWYzYzAtNDQ5NS1iZDU2LWI5MjIzMGNkNGI3NVwvZGVyZGw0bS1lYjkzODZjYS1iN2Q3LTQxODItYjZiNS0yN2M1NDk4ZWEyYTcuanBnIiwiaGVpZ2h0IjoiPD00NjciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLndhdGVybWFyayJdLCJ3bWsiOnsicGF0aCI6Ilwvd21cLzg2NGZiOTE3LWYzYzAtNDQ5NS1iZDU2LWI5MjIzMGNkNGI3NVwvY2hpYWtpaGF5YW5vLTQucG5nIiwib3BhY2l0eSI6OTUsInByb3BvcnRpb25zIjowLjQ1LCJncmF2aXR5IjoiY2VudGVyIn19.h29Zdl0xkvarw9hsZHSoida-YQVqBzYUFZb1HaYyySM)`,
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <div className="container">
        <div className="dz-bnr-inr-entry">
          <h1>{title}</h1>
            <ul className="breadcrumb">
              <li className="text-white">
              🌶️ Indicates Spicy, 
             
              🐟Indicates Raw
              <br />
Consumption of raw or undercooked foods of animal origin, such as beer, eggs, fish, lamb, pork, poultry or shellfish, may result in an increased risk of foodborne illness.
              </li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default CommonBanner
