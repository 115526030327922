import { getToken } from "./utlits";

const apiUrl = process.env.REACT_APP_API_URL;

const GET_HISTORY = 'order/GET_HISTORY';

const getHistory = (history) => ({
    type: GET_HISTORY,
    history
});

export const getHistoryThunk = (phone) => async (dispatch) => {
    const token = getToken()

    const response = await fetch(`${apiUrl}/api/orders/user/${phone}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });

    if (response.ok) {
        const data = await response.json();
        dispatch(getHistory(data));
        return data;
    }
};

const initialState = {
    history: [],
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HISTORY:
            return {
                ...state,
                history: action.history
            };
        default:
            return state;
    }
};

export default orderReducer;
