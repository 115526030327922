import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import App from './App';
import configureStore from '../src/store/';
import { AppContextProvider } from './context/AppContext';
import { restoreCSRF } from './store/csrf';


const store = configureStore();


if (process.env.NODE_ENV !== "production") {

  window.store = store;
}

function Root() {
  return (
    <AppContextProvider>
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>
    </AppContextProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
