import React, { createContext, useState } from "react"

const defaultState = {
  headerClass: false,
  setHeaderClass: () => {},
  showSignInForm: false,
  setShowSignInForm: () => {},
  showCategeryFilter: false,
  setShowCategeryFilter: () => {},
  showSidebar: false,
  setShowSidebar: () => {},
  headerSidebar: false,
  setHeaderSidebar: () => {},
  guestCheckOut: false,
  setGuestCheckOut: () => {},
  tipAmount: 0,
  setTipAmount: () => {},
  language: "en",
  setLanguage: () => {},
  changedCart: false,
  setChangedCart: () => {},
}

export const Context = createContext(defaultState)

export const AppContextProvider = ({ children }) => {
  const [headerClass, setHeaderClass] = useState(false)
  const [showSignInForm, setShowSignInForm] = useState(false)
  const [showCategeryFilter, setShowCategeryFilter] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [headerSidebar, setHeaderSidebar] = useState(false)
  const [guestCheckOut, setGuestCheckOut] = useState(false)
  const [tipAmount, setTipAmount] = useState(0)
  const [language, setLanguage] = useState("en")
  const [changedCart, setChangedCart] = useState(false)


  const contextValue = {
    headerClass,
    setHeaderClass,
    showSignInForm,
    setShowSignInForm,
    showCategeryFilter,
    setShowCategeryFilter,
    showSidebar,
    setShowSidebar,
    headerSidebar,
    setHeaderSidebar,
    guestCheckOut,
    setGuestCheckOut,
    tipAmount,
    setTipAmount,
    language,
    setLanguage,
    changedCart,
    setChangedCart,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
