import { getToken } from "./utlits";

const apiUrl = process.env.REACT_APP_API_URL;

const SET_USER = 'session/setUser';
const REMOVE_USER = 'session/removeUser';
const SET_TOKEN = 'session/setToken';
const REMOVE_TOKEN = 'session/removeToken';


const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
}



export const login = (user) => async (dispatch) => {
  const { credential, password } = user;
  const response = await fetch(`${apiUrl}/api/session`, {
    method: 'POST',
    body: JSON.stringify({
      credential,
      password,
    }),
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include'
    },
    credentials: 'include',
  });
  const data = await response.json();
  dispatch(setUser(data.user));

  if(data.token) {
    dispatch(setToken(data.token));
  }
  // dispatch(setToken(data.token));
  return response;
};

export const sessionLogin = (restaurant_id) => async (dispatch) => { 
  const response = await fetch(`${apiUrl}/api/session/create/sessionUser/${restaurant_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      credentials: 'include'
    },
    body: JSON.stringify(),
    credentials: 'include',
  });

  const data = await response.json();
  dispatch(setUser(data.user));
  dispatch(setToken(data.token));

}

export const signup = (user) => async (dispatch) => {
  const { phone, password, name, restaurant_id } = user;
  const response = await fetch(`${apiUrl}/api/users`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      credentials: 'include'
    },
    credentials: 'include',
    body: JSON.stringify({
      phone,
      password,
      name,
      restaurant_id
    }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  dispatch(setToken(data.token));

  return response;
};

export const logout = () => async (dispatch) => {
  const token = getToken();

  const response = await fetch(`${apiUrl}/api/session`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      credentials: 'include',
      'authorization': `Bearer ${token}`,
    },
    credentials: 'include',
  });
  
  if(response.ok) {
    dispatch(removeUser());
    dispatch(removeToken())
  }
  
  
  return response;
};

export const restoreUser = () => async (dispatch) => {
  const token = getToken();

  try {
    const response = await fetch(`${apiUrl}/api/session/restaurantUser`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${token}`, // Corrected header name
        'credentials': 'include',
      },
    });

    const data = await response.json();
    dispatch(setUser(data.user));

    return response;
  } catch (error) {
    // Handle fetch errors
    console.error('Error fetching user session:', error);
    throw error;
  }
};


export const sendVerification = (phone) => async dispatch => {
  const response = await fetch(`${apiUrl}/api/session/send-verification`, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include',
  },
  credentials: 'include',
    body: JSON.stringify({ phone }),
  });
  return response;
};

export const verifyCode = (phone, code) => async dispatch => {
  const response = await fetch(`${apiUrl}/api/session/verify`, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      credentials: 'include'
  },
  credentials: 'include',
    body: JSON.stringify({ phone, code }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};


const initialState = { user: null };

const sessionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_USER:
      newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    case REMOVE_USER:
      newState = Object.assign({}, state);
      newState.user = null;
      return newState;
    case SET_TOKEN:
      //set token in local storage
      localStorage.setItem('token', action.payload);
      return state;
    case REMOVE_TOKEN:
      //remove token from local storage
      localStorage.removeItem('token');
      return state;
    default:
      return state;
  }
};


export default sessionReducer;
